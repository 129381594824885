






























































import _ from 'lodash';
import { Research } from '@app/models';
import { BlockElement, FlowTree } from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import DropdownLanguage from '../../components/6-other/dropdown-language.vue';
import ResearchTargetTabs from '../../components/research/research-target-tabs.vue';
import { ModuleModule } from '../../store/modules/module-module';
import { StorageModule } from '../../store/modules/storage-module';

@Component({
  components: { DropdownLanguage, ResearchTargetTabs },
})
export default class ResearchExample extends Vue {
  private modules: ModuleModule = getModule(ModuleModule);
  private storage: StorageModule = getModule(StorageModule);
  private research: Research | null = null;
  // private researchModules: any = [];
  private currentModule: any = '';

  @Prop()
  public value!: Research.Mutable;

  @Prop()
  public targets!: string[];

  @Prop({ default: webpack.FEATURES.languages[0] })
  public language!: string;

  private target = this.targets[0] || '';

  public get researchModules() {
    if (!this.value) return [];

    return this.modules.all
      .filter(module => {
        const moduleTarget = module.category.split('+');
        const { license } = module.metadata;
        const { active } = module.settings;

        const target = this.value.target.some(
          t => moduleTarget.indexOf(t) >= 0
        );
        return target && active;
      })
      .sort((a, b) => a.metadata.order - b.metadata.order);
  }

  public get modulesNames() {
    return this.researchModules
      .filter(module => {
        return (
          module.category.split('+').some(c => c.startsWith(this.target)) &&
          (module.metadata.fsq ? !!this.formuleSpecificQuestions.length : true)
        );
      })
      .map(module => {
        return {
          name: module.name,
          id: module.id,
          startblock: module.flow.start.id,
          metadata: module.metadata,
        };
      });
  }

  public get formuleSpecificQuestions() {
    return this.value && (this.value.questions as BlockElement[]);
  }

  public get moduleBlock() {
    const current = this.currentModule;

    if (!current)
      return {
        module: { id: '', version: 'v1' },
        block: { id: '', version: 'v1' },
      };

    return {
      module: { id: current.id, version: 'v1' },
      block: { id: current.startblock, version: 'v1' },
    };
  }

  public get strategicValues() {
    return this.value && this.value['strategic-value'];
  }

  public get getArrayBlocks() {
    const module = this.modules.current && this.modules.current.flow;
    if (!module) return [];

    const flowTree = new FlowTree(module).nodes
      .map(node => this.modules.blocks.find(b => b.id === node.id))
      .filter((v: any) => !!v);

    if (
      this.modules.current!.metadata.fsq &&
      flowTree &&
      flowTree[0] &&
      flowTree[0].elements &&
      this.formuleSpecificQuestions
    ) {
      flowTree[0].elements = this.formuleSpecificQuestions;
    }

    return flowTree;
  }

  @Watch('target')
  public watchTarget() {
    const [first] = this.modulesNames;
    if (first) this.setModule(first.id);
  }

  public async created() {
    await this.modules.bind();
    // this.value = await this.getResearch(this.$route.params.id);
    this.currentModule = this.modulesNames[0];
    await this.modules.load(this.moduleBlock);
  }

  public transformModuleName(module: any) {
    const { category, name: moduleName } = module;
    if (category === 'entrepreneurs+headquarters') return { moduleName };
    else
      return {
        moduleName,
        target: `(${
          category === 'entrepreneurs'
            ? this.$t('entrepreneurs')
            : this.$t('headquarters')
        })`,
      };
  }

  // public get transformModuleName() {
  //   return (module: string) => {
  //     const [moduleName, target] = module.split('(');
  //     if (!target) return { moduleName };
  //     return { moduleName, target: `(${target}` };
  //   };
  // }

  public async setModule(id: string) {
    this.currentModule = this.modulesNames.find(m => m.id === id);
    await this.modules.load(this.moduleBlock);
  }

  public getElementData(element: BlockElement) {
    if ((element as any).question) {
      (element as any).question = (element as any).question.replace(
        /{formule}/g,
        this.$company.data && this.$company.data.name
      );
    }
    if (
      this.strategicValues &&
      element.type === 'radio' &&
      element.metadata &&
      (element.metadata.dashboard === 'strategic-values' ||
        element.metadata.dashboard === 'missing-values')
    ) {
      const options = this.strategicValues
        .filter(v => !!v)
        .map(val => ({ text: val }));
      return { ...element, options };
    }
    return element;
  }

  public async getResearch(researchId: string) {
    const researchRef = await this.$firebase.col('research').doc(researchId);
    return researchRef
      .get()
      .then(doc => (doc.exists ? (doc.data() as Research) : null));
  }
}
